import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["textfield"];

    connect() {
        this.textfieldTargets.forEach(function(target){
            Array.from(target.getElementsByTagName('a')).forEach(function(anchor){
                anchor.setAttribute('target', '_blank');
            });
        });
    }

}