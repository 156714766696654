var Trix = require("trix");

/* what the newly created button does */
Trix.config.textAttributes.em = {
    tagName: 'em',
    inheritable: true,
    breakOnReturn: false
}

/* insert the button visual in the default toolbar */
addEventListener("trix-initialize", function(event) {
    var buttonHTML = '<button type="button" class="trix-button" data-trix-attribute="em">&nbsp;&nbsp;H&nbsp;&nbsp;</button>'

    event.target.toolbarElement.
    querySelector(".trix-button-group--text-tools").
    insertAdjacentHTML("beforeend", buttonHTML)
})

$(document).on("turbo:load turbo:frame-render", () => {

	$('#project-edit-werkliste-toggle, #project-edit-portfolio-toggle').on('click', function() {
		if($(this).hasClass('enabled')){
			$('#project-edit-werkliste').toggleClass('hidden');
			$('#project-edit-portfolio').toggleClass('hidden');
			$('#project-edit-werkliste-toggle').toggleClass('active enabled');
			$('#project-edit-portfolio-toggle').toggleClass('active enabled');
		}
	});

	$('#project_category_id').on('change', function (e) {
	    var optionSelected = $("option:selected", this);
	    if( optionSelected.text() == "Anderes") {
	    	$('#custom_category').removeClass('hidden');
	    	$('input[type=text]', '#custom_category').attr('required', true);
	    }
	    else{
	    	$('#custom_category').addClass('hidden');
	    	$('input[type=text]', '#custom_category').attr('required', false);
	    }
	});

	$('#project_portfolio').on('change', function (e) {
		if($(this).is(":checked")){
			if($('#project_image').attr('data-present') == "false")
				$('#project_image').attr('required', true);
		}
		else {
			$('#project_image').attr('required', false);
		}
	});

	$('.edit-toggle').on('click', function() {
		if($(this).hasClass('enabled')) {
			let target = $(this).attr('data-target');
			$('.edit-toggle').removeClass('active');
			$('.edit-toggle').addClass('enabled');
			$(this).addClass('active');
			$(this).removeClass('enabled')
			$('.edit').addClass('hidden');
			$('#' + target).removeClass('hidden');
		}
	});

	// https://stackoverflow.com/questions/22626190/resizing-image-before-upload
	$('#project_image').change(function(e){
	    const file = this.files[0];
	    if (file){
	      let reader = new FileReader();
	      reader.onload = function(event){
	        var img = new Image();
	        img.onload = function () {
	            var MAX_WIDTH = 3000;
	            var MAX_HEIGHT = 3000;
	            var width = img.width;
	            var height = img.height;

	            if (width > height) {
	                if (width > MAX_WIDTH) {
	                    height *= MAX_WIDTH / width;
	                    width = MAX_WIDTH;
	                }
	            } else {
	                if (height > MAX_HEIGHT) {
	                    width *= MAX_HEIGHT / height;
	                    height = MAX_HEIGHT;
	                }
	            }

	            var canvas = document.createElement("canvas");
	            canvas.width = width;
	            canvas.height = height;
	            canvas.getContext("2d").drawImage(this, 0, 0, width, height);
	       			$('#project_image_preview').attr('src', canvas.toDataURL());
	       			$('#project_image_orientation').prop('checked', img.height > img.width );
	        }
	        img.src = event.target.result;
	      }
	      reader.readAsDataURL(file);
	    }
	});

});