// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import "@hotwired/stimulus"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
var jQueryBridget = require('jquery-bridget');
var Masonry = require('masonry-layout');
jQueryBridget( 'masonry', Masonry, $ );
var imagesLoaded = require('imagesloaded');
imagesLoaded.makeJQueryPlugin( $ );
import sync from 'css-animation-sync';

Rails.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")
require("@hotwired/stimulus")

require("gallery")
require("werkliste")
require("cms")

import "controllers"

function setVh() {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}

$(document).on("turbo:load", () => {
	
	$(window).on('resize', () => { setVh(); });
	setVh();

	let colorIntervId;
	if(!colorIntervId){
		colorIntervId = setInterval(
			function(){
				let currentThemeColor = $(window)[0].getComputedStyle($('.project-theme-reference')[0]).getPropertyValue('color');
				let currentThemeColorTransparent = "rgba(" + currentThemeColor.slice(4, -1) + ",0)";

				$(':root')[0].style.setProperty('--theme-color', currentThemeColor);
				$(':root')[0].style.setProperty('--theme-color-transparent', currentThemeColorTransparent);

			}
		, 250)
	}
});

$(document).on("turbo:load turbo:frame-render", () => {

	// https://github.com/bealearts/css-animation-sync
	sync('themeColorAnimation');

	$('.project-root-bg-wrapper').on('click wheel touchmove', function() {
		$('.project-root-bg').addClass('project-root-bg-animate');
		$('.project-root-spacer').addClass('project-root-spacer-animate');
		setTimeout(function(){
			$('.header-sticky').css('display', 'flex');
			$('.header-right').css('display', 'flex');
		}, 2000);
		setTimeout(function(){
			$('main').css('display', 'flex');
			$('.header-mobile').css('background-color', 'white');
			$('.project-root-bg-wrapper').remove();
		}, 2000);
	});

});