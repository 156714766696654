const isTouchDevice = () => {
  return window.matchMedia("(pointer: coarse)").matches
}

$(document).on("turbo:load turbo:frame-render", () => {

	var gallery = $('.project-gallery').masonry({
		columnWidth: '.project-gallery-image',
			itemSelector: '.project-gallery-image',
			gutter: 20
	});

	$('.project-gallery').imagesLoaded().progress(() => {
		gallery.masonry('layout');
	})

	$('.project-gallery-slider-close').on('click', function() {
		$('#project-gallery-slider-frame').addClass('hidden');
		$('main.project').removeClass('hidden');
		$('.header-sticky').removeClass('hidden');
		gallery.masonry('layout');
	})

	$('.project-gallery-image').on('click', function() {
		$('#project-gallery-slider-frame').removeClass('hidden');
		$('main.project').addClass('hidden');
		$('.header-sticky').addClass('hidden');
		let selectedImageId = $(this).attr('data-image');
		let scrollToImage = $('.project-gallery-slider-image[data-image=' + $(this).attr('data-image') + ']', '.project-gallery-slider');
		let imagesBefore = $('.project-gallery-slider-image').slice(0, parseInt(selectedImageId));
		let scrollLeftAmount = imagesBefore.toArray().reduce(
			function (accumulatedWidth, item) {
  				return accumulatedWidth + $(item).width();
			}, 0);
		$('.project-gallery-slider').scrollLeft(scrollLeftAmount);
	})

	// https://stackoverflow.com/questions/23952491/jquery-mousewheel-scroll-horizontally/36879891#36879891
	$('.project-gallery-slider').on('wheel', function(e){
		var delta = Math.max(-1, Math.min(1, (e.originalEvent.wheelDelta || -e.originalEvent.detail)));
		$('.project-gallery-slider').scrollLeft( $('.project-gallery-slider').scrollLeft() - ( delta * 15 ) );
		e.preventDefault();
	});

	var slidertimer = null;
	var slidertimerStarted = false;

	$('.project-gallery-slider').on('mousemove', function(e){
		if(!isTouchDevice()){	
			if(e.pageX < 150 || e.pageX > ($(window).width() - 150)) {

				if(!slidertimerStarted) {
					slidertimer = (e.pageX < 150) ? setInterval(function(e) {
						$('.project-gallery-slider').scrollLeft($('.project-gallery-slider').scrollLeft() - (1));
					}, 5) : setInterval(function(e) {
						$('.project-gallery-slider').scrollLeft($('.project-gallery-slider').scrollLeft() + (1));
					}, 5) 

					slidertimerStarted = true;
				}

				let maxScrollLeftAmount = $('.project-gallery-slider-image').toArray().reduce(
				function (accumulatedWidth, item) {
	  				return accumulatedWidth + $(item).width();
				}, 0)

				if(e.pageX < 150 && $('.project-gallery-slider').scrollLeft() > 0){
					$(this).removeClass('project-gallery-slider-cursor-right');
					$(this).addClass('project-gallery-slider-cursor-left');
				}
				else if(e.pageX < 150 && $('.project-gallery-slider').scrollLeft() == 0){
					$(this).removeClass('project-gallery-slider-cursor-left');
				}
				else if(e.pageX > ($(window).width() - 150)){
					$(this).removeClass('project-gallery-slider-cursor-left');
					$(this).addClass('project-gallery-slider-cursor-right');
				}
				else {
					$(this).removeClass('project-gallery-slider-cursor-right');
				}
			}
			else {
				slidertimerStarted = false;
				clearInterval(slidertimer);
				$(this).removeClass('project-gallery-slider-cursor-right');
				$(this).removeClass('project-gallery-slider-cursor-left');
			}
		}
	});
	$('.project-gallery-slider').on('mouseout', function(e){
			slidertimerStarted = false;
			clearInterval(slidertimer);
			$(this).removeClass('project-gallery-slider-cursor-right');
			$(this).removeClass('project-gallery-slider-cursor-left');
	});

	$('.project-gallery-slider-image').on('click', function() {
		if(!isTouchDevice()){
			let selectedImageId = $(this).attr('data-image');
			let scrollToImage = $('.project-gallery-slider-image[data-image=' + $(this).attr('data-image') + ']', '.project-gallery-slider');
			let imagesBefore = $('.project-gallery-slider-image').slice(0, parseInt(selectedImageId));
			let scrollLeftAmount = imagesBefore.toArray().reduce(
				function (accumulatedWidth, item) {
	  				return accumulatedWidth + $(item).width();
				}, 0) - ( $(window).width() - scrollToImage.width() )/2;
			$('.project-gallery-slider').animate({scrollLeft: scrollLeftAmount}, 1000);
		}
	});
});