function toggleData(elem, attr, onValue, offValue) {
	if(elem.attr(attr) == onValue){
		$(elem).attr(attr, offValue);
	}
	else
		$(elem).attr(attr, onValue);
}

$(document).on("turbo:load turbo:frame-render", () => {

	$('.werkliste-filter-block, .werkliste-filter-header, .werkliste-filter-collapse .werkliste-filter-collapse-cross').on('click', function(e) {
		if($(e.target).is('.werkliste-filter-close')){
			$('.werkliste-filter-content').addClass('hidden');
			$('.werkliste-filter-collapse-cross').addClass('hidden');
			$('.werkliste-filter-block').addClass('werkliste-filter-block-closed');
		}
		else if(!$(e.target).is('.werkliste-filter-entry')) {
			$('.werkliste-filter-content').removeClass('hidden');
			$('.werkliste-filter-collapse-cross').removeClass('hidden');
			$('.werkliste-filter-block').removeClass('werkliste-filter-block-closed');
			$('.werkliste-filter-active-filter').html('');
			$('.werkliste-project-group').removeClass('hidden');
		}
	});

	$('.werkliste-filter-entry').on('click', function(e) {
		$('.werkliste-filter-active-filter-' + $(this).attr('data-filter')).html($(this).html());
		$('.werkliste-filter-content').addClass('hidden');
		$('.werkliste-filter-collapse-cross').addClass('hidden');
		$('.werkliste-filter-block').addClass('werkliste-filter-block-closed');
		$('.werkliste-project-group:not([data-project-' + $(this).attr('data-filter') + '="' + $(this).attr('data-filter-value') + '"])').addClass('hidden')
	});

	$('.werkliste-project-block, .werkliste-project-header, .werkliste-project-teaser', 'main.werkliste').on('click', function(e) {
		let this_ = $(this).closest('.werkliste-project-block')
		let elem = $('.werkliste-project-teaser', this_);
		if(this_.attr('data-project-portfolio') == 'true'){
			if(elem.attr('data-expanded') == 'false' && !$(e.target).is('.werkliste-close') && !$(e.target).is('polygon') ) {
				toggleData(elem, 'data-expanded', 'true', 'false');
				this_.addClass('werkliste-project-gradient');
				this_.removeClass('werkliste-project-gradient-hover');
				$('.werkliste-project-content', this_).removeClass('hidden');
				$('.werkliste-project-collapse-cross', this_).removeClass('hidden');
				$('.werkliste-project-collapse-arrow', this_).addClass('hidden');
			}
			else if(elem.attr('data-expanded') == 'true' && ($(e.target).is('.werkliste-close') || $(e.target).is('polygon') )) {
				toggleData(elem, 'data-expanded', 'true', 'false');
				this_.removeClass('werkliste-project-gradient');
				this_.addClass('werkliste-project-gradient-hover');
				$('.werkliste-project-content', this_).addClass('hidden');
				$('.werkliste-project-collapse-cross', this_).addClass('hidden');
				$('.werkliste-project-collapse-arrow', this_).removeClass('hidden');
			}
		}
	});

});