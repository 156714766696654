import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["frame", "loading", "form", "file", "submit"];
    static values = {
        filesize: Number
    }

    connect() {
        this.element.addEventListener('turbo:frame-load', this.addFileListener.bind(this), {});
        this.element.addEventListener('turbo:submit-start', this.onFormSubmitStart.bind(this), {});
        this.element.addEventListener('turbo:submit-end', this.onFormSubmitted.bind(this), {});
    }

    addFileListener(){
        this.fileTarget.addEventListener('change', this.onFileSelect.bind(this), {});
    }

    onFileSelect() {
        if(this.fileTarget.files[0].size && this.fileTarget.files[0].size > this.filesizeValue){
            alert("Datei zu gross.");
            this.submitTarget.setAttribute('disabled','disabled');
        }
        else {
            this.submitTarget.removeAttribute('disabled');
        }
    }

    onFormSubmitStart() {
        this.formTarget.classList.add('hidden');
        this.loadingTarget.classList.remove('hidden');
    }

    onFormSubmitted() {
        this.frameTarget.reload();
    }

}